import React from 'react';
import PropTypes from 'prop-types';
import '../styles/layout.scss';

const Layout = props => {
  const { childClass, children } = props;
  return (
    <div className="site">
      <div className={childClass}>{children}</div>
    </div>
  );
};

Layout.defaultProps = {
  children: {},
  childClass: '',
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  childClass: PropTypes.string,
};

export default Layout;
