import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link, StaticQuery } from 'gatsby';
import ReactMarkdown from 'react-markdown/with-html';
import makeid from '../utils/functions';
import Layout from '../components/layout';
import '../styles/index.scss';
import Icon0 from '../../static/img/star-light.svg';
import Icon1 from '../../static/img/tally-light.svg';
import Icon2 from '../../static/img/handshake-light.svg';
import Icon3 from '../../static/img/shopping-cart-light.svg';
import Icon4 from '../../static/img/calendar-alt-light.svg';
import Icon5 from '../../static/img/thumbs-up-light.svg';

const mapIcon = i => {
  switch (i) {
    case 0:
      return <img src={Icon0} height="25" alt="" />;
    case 1:
      return <img src={Icon1} height="25" alt="" />;
    case 2:
      return <img src={Icon2} height="28" alt="" />;
    case 3:
      return <img src={Icon3} height="25" alt="" />;
    case 4:
      return <img src={Icon4} height="25" alt="" />;
    case 5:
      return <img src={Icon5} height="25" alt="" />;
    default:
      return null;
  }
};

const mapContentA = (contentArr, first, last) => {
  const blocks = [];
  for (let i = first; i < last; i += 1) {
    const content = (
      <div className="content-block" key={makeid()}>
        <div aria-hidden="true" className="dd-icon">
          {mapIcon(i)}
        </div>
        <div className="title">{contentArr[i].title}</div>
        <div className="block">
          <ReactMarkdown source={contentArr[i].block} />
        </div>
      </div>
    );
    blocks.push(content);
  }
  return blocks;
};

const mapContentB = (contentArr, first, last) => {
  const blocks = [];
  for (let i = first; i < last; i += 1) {
    if(contentArr[i] === undefined){
      continue;
    }
    const content = (
      <div className="content-block" key={makeid()}>
        <div className="left-col">
          <div className="title">{contentArr[i].title}</div>
          <div aria-hidden="true" className="dd-icon">
            {mapIcon(i)}
          </div>
        </div>
        <div className="block">{contentArr[i].block}</div>
      </div>
    );
    blocks.push(content);
  }
  return blocks;
};

const IndexPage = props => {
  const [email, setEmail] = useState(null);
  const [success, setSuccess] = useState(false);
  const {
    data: {
      cms: { fcSites },
    },
  } = props;
  const site = fcSites[0];
  const contentBlocks = site.fcContent;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);
      setEmail(params.get('email'));
      setSuccess(params.get('success'));
    }
  }, []);

  return (
    <Layout childClass="index">
      <div className="hero">
        <div className="log-in">
          <Link to="/account">
            <button type="button">{site.labels.LOG_IN}</button>
          </Link>
          <div className="forgotten">
            <a href={`mailto:${site.labels.HELP_EMAIL}?subject=${encodeURI(site.labels.FORGOT)}`}>
              {site.labels.FORGOT}
            </a>
          </div>
        </div>
      </div>
      {email && success && <div className="message">{site.labels.PASSWORD_SUCCESS}</div>}
      <div className="content-a">{mapContentA(contentBlocks, 0, 3)}</div>
      <div className="content-b">
        <div className="tagline">{site.labels.TAGLINE}</div>
        {mapContentB(contentBlocks, 3, 6)}
      </div>
      <div className="hr">
        <hr />
      </div>
      <div className="disclaimer">{site.labels.DISCLAIMER}</div>
      <footer>
        <span>
          {`${site.labels.HELP_ACCOUNT} `}
          <a
            href={`mailto:${site.labels.HELP_EMAIL}?subject=${encodeURI(site.labels.SUBJECT_HELP)}`}
          >
            {site.labels.CONTACT_US}
          </a>
        </span>
      </footer>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    cms: PropTypes.shape({
      fcSites: PropTypes.array,
    }),
  }),
};

IndexPage.defaultProps = {
  data: {},
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        cms {
          fcSites {
            fcContent {
              title
              block
            }
            labels
          }
        }
      }
    `}
    render={data => <IndexPage {...props} data={data} />}
  />
);
